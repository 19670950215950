import React, { useRef } from 'react';


export function WideWall(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();
  // Set up state for the hovered and active state

  // Subscribe this component to the render-loop, rotate the mesh every frame
 
  // Return view, these are regular three.js elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={mesh}
      scale={1}
    >
      <boxBufferGeometry args={[props.x, props.y, props.z]} />
      <meshStandardMaterial color={'white'} />
    </mesh>
  );
}

