import React, {} from 'react'; 
import { Physics, usePlane, useBox } from "@react-three/cannon";


export function Plane() {
	const [ref] = usePlane(() => ({
		rotation: [-Math.PI / 2, 0, 0],
	}));
	return (
		<mesh visible ref={ref} position={[0, 0, 0]} rotation={[-Math.PI / 2, 10, 5]} >
			<planeBufferGeometry attach="geometry" args={[100, 100]} />
			<meshLambertMaterial attach="material" color="#8a8888" />
		</mesh>
	);
}